import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import "amfe-flexible"
import './assets/global.css'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import VMdEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import VMdPreview from '@kangc/v-md-editor/lib/preview';
import '@kangc/v-md-editor/lib/style/preview.css';
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import '@kangc/v-md-editor/lib/theme/style/github.css';
import hljs from 'highlight.js';
import Prism from 'prismjs';
import http from "@/utils/http"
import downloader from "@/utils/downloader";
import i18n from "@/locale"
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import './assets/custom.css'
import {format} from 'silly-datetime';
const OpenCC = require("opencc-js")

VMdEditor.use(githubTheme, {
    Prism,
});
VMdPreview.use(githubTheme, {
    Hljs: hljs,
});

const app = createApp(App)
app.use(router)
app.use(ElementPlus)
app.use(VMdEditor)
app.use(VMdPreview)
app.use(i18n)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.config.globalProperties.$axios = http
app.config.globalProperties.$downloader = downloader
app.config.globalProperties.$format = format
const converter1 = OpenCC.Converter({ from: 'cn', to: 'tw' });
function cn2tw(s){
    if (s){
        return converter1(s)
    }else {
        return ''
    }
}
app.config.globalProperties.$cn2tw = cn2tw
const converter2 = OpenCC.Converter({ from: 'tw', to: 'cn' });
function tw2cn(s){
    if (s){
        return converter2(s)
    }else {
        return ''
    }
}
app.config.globalProperties.$tw2cn = tw2cn
app.mount('#app')
