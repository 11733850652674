export default {
    global: {
        projectType: {
            type3: '02 新品上市前策',
            type4: '03 社交口碑营销',
            type6: '04 整合营销创意'
        },
        params: {
            param1: '产品Slogan与Slogan诠释',
            param2: '传播口号与传播口号创意说明',
            param3: '传播主题Theme',
            param4: '核心创意概述',
        },
        successMsg: '恭喜你，成功完成！',
        loginFailed: '登录异常，请联系客服。',
        errorMsg: 'Oops，出现了错误。',
        InsufficientBalance: '额度不足，请充值',
        upload: {
            notice: '请上传单份大小不超过{fileSize}MB，格式为{fileTypes}的文件，最多5份',
            exceed: '最多上传5个文件',
            typeError: '文件格式不正确, 请上传{fileTypes}格式文件!',
            sizeError: '上传文件大小不能超过{fileSize}MB!'
        },
        concurrentError:{
            title:'权限不足',
            content:'目前有任务在进行中，如您想同时让艾迪处理多个任务，欢迎联系商务升级为超级会员',
            ok:'我知道了'
        },
        title:{
            marketingTask:'本次ADGo的任务'
        },
        button:{
            clear:'清空',
            expand:'智能补全'
        },
        message:{
            emptyMarketingTask:'请填写本次营销任务'
        }
    },
    views: {
        LoginPage: {
            title: '欢迎来到',
            username: '请输入账号',
            password: '请输入密码',
            login: '登录',
            qrcode: {
                title: '在线客服'
            }
        },
        TemplateSelection: {
            pageDesc: '创意灵感生成式 AI',
            template1: {
                title: '02 新品上市前策',
                desc: '为新品上市推广，推导完成传播策略分析与传播口号创意。',
                dialog: {
                    suitableScene: '全新产品上市、创作产品传播口号、需要前端传播策略推导',
                    noIdea: {
                        output1: '人群洞察创意标签',
                        output2: '新品传播口号创意',
                    },
                    hasIdea: {
                        output1: '市场分析',
                        output2: '竞品分析',
                        output3: '产品卖点分析',
                        output4: '人群分析',
                        output5: '制胜传播策略',
                        output6: '新品传播定位',
                        output7: '新品传播口号'
                    }
                }
            },
            template2: {
                title: '03 社交口碑营销',
                desc: '为产品的社交平台推广，推导完成社交心智、传播主题与核心创意。',
                dialog: {
                    suitableScene: '产品/品牌/事件、社交平台推广、产出社交口碑心智',
                    noIdea: {
                        output1: '人群洞察',
                        output2: '传播主题与核心创意',
                    },
                    hasIdea: {
                        output1: '社交口碑心智',
                        output2: '定义差异化核心价值',
                        output3: '人群分析',
                        output4: '人群洞察创意标签',
                        output5: '制胜心智策略',
                        output6: '传播主题与核心创意',
                        output7: '创意文案词组',
                    },
                    notice: '注意：方案不含市场分析、竞品分析与产品卖点分析，若需要请选择“新品上市前策”。'
                }
            },
            template3: {
                title: '04 整合营销创意',
                desc: '为产品的社交平台推广，打造社交热点事件，完成整合营销创意方案。',
                dialog: {
                    suitableScene: '产品/品牌/事件、社交平台推广、产出整合营销全案创意',
                    noIdea: {
                        output1: '人群洞察',
                        output2: '传播主题与核心创意',
                        output3: 'Buzz Point 热点事件创意',
                    },
                    hasIdea: {
                        output1: '人群洞察',
                        output2: '传播主题与核心创意',
                        output3: 'Buzz Point 热点事件',
                        output4: 'Teaser 阶段创意与话题',
                        output5: 'Launch 阶段创意与话题',
                        output6: 'Sustain 阶段创意与话题',
                        output7: '扩散/ 转化/ 平台选择',
                        output8: 'KOL 破圈话题创意',
                        output9: 'KOL 量级推荐与话题创意',
                        output10: 'KOL 投放矩阵',
                    }
                }
            },
            template4: {
                title: '01 市场情报官',
                desc: '快速拟定市场策略、了解竞争品牌、洞察消费者、分析产品卖点、分析营销需求。',
                dialog: {
                    suitableScene: '快速拟定市场策略、了解竞争品牌、洞察消费者、分析产品卖点、分析营销需求。',
                    outputs: {
                        output1: '市场竞争情况报告',
                        output2: '竞品品牌对比报告',
                        output3: '本品优势卖点和消费者评价',
                        output4: '目标人群的产品洞察报告',
                        output5: '行业媒介投放情况报告',
                        output6: '竞品媒介投放情况报告',
                        output7: '竞品传播策略分析报告'
                    }
                }
            },
            template5: {
                title: '07 带货短视频创意',
                desc: '卖点梳理，用户买点挖掘，带货短视频脚本创意，拍摄脚本',
                dialog: {
                    suitableScene: '卖点梳理，用户买点挖掘，带货短视频脚本创意，拍摄脚本',
                    outputs: {
                        output1: '十大产品卖点',
                        output2: '十大用户买点',
                        output3: '根据卖点和买点创作的脚本创意',
                        output4: '根据创意的实际拍摄脚本',
                    }
                },
                alias: '带货Go'
            },
            template6: {
                title: '05 全球创意案例',
                desc: '查找参考案例，参考案例出创意，为创意寻找案例验证',
                dialog: {
                    suitableScene: '查找参考案例，参考案例出创意，为创意寻找案例验证',
                    outputs: {
                        output1: '6个参考案例',
                        output2: '对应案例的二创',
                        output3: '为创意寻找相似案例'
                    }
                }
            },
            template7: {
                title: '06 头脑核爆',
                desc: '头脑风暴的核爆级版本，大量！高速！产出各式各样的优秀创意灵感，专治灵感枯竭。',
                dialog: {
                    suitableScene: 'IDEA 探索，IDEA 扩展',
                    outputs: {
                        output1: '5个IDEA'
                    }
                }
            },
            modal: {
                suitableScene: '适合场景：',
                noIdea: '脑暴模式输出',
                hasIdea: '完整方案输出',
                output: '输出',
                ok: '我知道了'
            },
            recentProject: {
                title: '你最近的方案',
                mode: {
                    noIdea: '脑暴模式',
                    hasIdea: '完整方案模式',
                    research: '情报调研',
                    shortVideoScript: '带货短视频创意',
                    tips: {
                        reference: '任务查找',
                        similar: '创意查找'
                    },
                    explosion: {
                        explore:'IDEA 探索',
                        expand:'IDEA 扩展'
                    }
                }
            },
            msg: {
                title: '权限不足',
                content: '您尚未开通此功能，如想使用，请先付费购买',
                ok: '去付费',
                cancel: '取消'
            },
            announcement: {
                title: 'ADGO新功能',
                content: '<div>1.方案任务智能补全：AI一键直接补全任务，让你的方案开始的更专业，想你所想</div>' +
                    '<div>2.全球创意案例：10W+全球案例库智能检索，从你的营销任务为你直接寻找案例</div>'+
                    '<div>3.头脑核爆：顶级创意直接获取，省去所有推导过程，IDEA is all you need</div>',
                ok: '我知道了'
            }
        },
        ModeSelection: {
            button: {
                return: '返回',
                generate: '生成Brief',
                research: '开始调研'
            },
            pageDesc: '今天你想创造什么？',
            noIdea: '头脑风暴模式',
            hasIdea: '完整方案模式',
            giveWhat: {
                idea: "很多超棒的IDEA",
                plan: "超完整的思路和方案"
            },
            label1: '本次营销任务（必填）：',
            label2: '传播主题 Theme（选填，若不填写 ADGO 将为你想出来）：',
            label3: '核心创意概述（选填，若不填写 ADGO 将为你想出来）：',
            label4: '传播主题文案（选填，若不填写 ADGO 将为你想出来）：',
            label5: '传播主题创意概念说明（选填，若不填写 ADGO 将为你想出来）：',
            label6: '疯狂模式：',
            tooltip6: '打开它，你会获得许多异想天开的创意',
            label7: '联网搜索：',
            tooltip7: '打开它，艾迪会在需要时通过互联网搜集资料',
            label8: '点击<span class="upload-text">上传</span>资料（选填）：',
            placeholder8: '上传本次营销任务相关的资料，如，产品介绍文档、市场调研报告、人群洞察报告、消费者反馈情况等，艾迪暂时还不能查看文档中的图片，请尽量提供文字版',
            label9: '智能生成：',
            tooltip9: '打开它，艾迪会检查您的本次营销任务并提出补充建议',
            placeholder1: '请告诉艾迪这次新品上市的营销任务的需求，可能包括但不限于以下信息：品牌名/ 产品或活动名/ 产品或活动的卖点描述/ 目标人群基础资料/ 主打市场/ 竞争品牌的产品或活动/ 竞争品牌的产品或活动的卖点描述。\n\n如需要参考，你也可以点击下方的范例。',
            placeholder2: '请输入已经完成的传播主题创意，可能包括但不限于：传播主题文案/ 传播主题创意说明。接下来ADGo将会基于你提供的传播主题，继续完成全部方案。\n\n如需要参考，你也可以点击下方的范例。',
            placeholder3: '请输入已经完成的主创意，可能包括但不限于：创意概念/ 创意手法/ 媒体创意/ 创意内容/ 等具体执行创意。接下来ADGo将会基于你提供的主创意描述，继续完成全部方案。\n\n如需要参考，你也可以点击下方的范例。',
            placeholder4: '请输入已经完成的传播主题文案，可能包括但不限于：传播主题文案/ Slogan。接下来ADGo将会基于你提供的传播主题文案，继续完成全部方案。\n\n如需要参考，你也可以点击下方的范例。',
            placeholder5: '请输入传播主题文案的创意概念说明，可能包括但不限于：创意概念/ 创意洞察/ 文案手法/ 创意来源/ 为何打动人/ 等文案创意的详细介绍。接下来ADGo将会基于你提供的传播主题创意，继续完成全部方案。\n\n如需要参考，你也可以点击下方的范例。',
            example: {
                title: '参考范例',
            },
            example1: {
                title: '范例一',
                brief: 'Under armour即将在2024年上新一款鞋slipspeed，它的核心卖点是鞋后跟可以折叠，折叠起来日常休闲可当拖鞋使用，鞋跟立起可以作为专业的训练鞋在运动中使用，品牌想借此新品上市，在推广产品的同时，突破品牌在消费者心中硬核专业的形象，拓展更多圈层人群，此鞋款受众18-30岁，主要竞品产品：Nike Metcon 9、Reebok Nano X4。',
                spreadTheme: '脚下解锁，生活无限可能',
                coreIdea: '此创意源自现代都市生活的多面性，结合SlipSpeed可折叠后跟的独特设计，创造出"解锁"这一富有想象力的概念。它不仅暗示了产品的变形功能，更寓意着解锁生活中的无限可能。这一主题迎合了年轻人追求效率与多元化的需求，引发他们对简化生活、提升品质的共鸣。同时，巧妙运用了当下流行的"解锁新姿势"社交热梗，激发用户的好奇心和参与欲，符合"新奇有趣"的社交动机。'
            },
            example2: {
                title: '范例二',
                brief: '雀巢阿拉比卡咖啡品种「Star 4」，强调低碳排放和供应链优化，特别吸引环保意识强烈和注重质量的咖啡爱好者，精准锁定中国市场中注重生活质量和环境永续的都市人士。这款咖啡不仅具有独特风味，还特别强调其对应气候变迁的创新性，适合日常饮用和特殊场合品尝。对标其他精品咖啡品牌，强调雀巢在环保创新、质量控制以及全球供应链优化上的优势。',
                spreadTheme: '「一杯咖啡，一份责任」',
                coreIdea: '在这个对环保意识日益重视的时代，雀巢Star 4不仅满足了顾客对咖啡口感的苛刻要求，更满足了他们对环保的道德期待。每一杯Star 4，都是对生活质量与环境保护双重承诺的展示，它不只是咖啡，更是一份对未来的责任。'
            },
            example3: {
                title: '范例一',
                brief: '全家便利商店的「开学季优惠」活动 活动内容： 全家便利商店在小红书上针对大学生举办了「开学季优惠」活动，发布各种折扣和优惠信息，同时在小红书上进行问答互动，解答学生的各种问题。 目标明确：精准锁定大学生群体，活动设计符合他们的需求和喜好。 平台互动：利用小红书的社交互动功能，增加用户参与度和品牌好感度。 实用性强：提供实际的折扣和优惠，直接提升门店销量。竞争品牌：7-ELEVEN、莱尔富、全家 FamilyMart 的开学季活动。',
                spreadTheme: '「全家の深夜食堂：熬夜学霸篇」\n创意描述：全家便利商店与日本知名漫画IP《深夜食堂》合作，推出「全家の深夜食堂：熬夜学霸篇」特别企划。我们将《深夜食堂》温馨治愈的风格与中国学生的熬夜学习生活相结合，创造出一系列温暖人心的漫画短篇和动画。',
                coreIdea: '主要内容包括： 漫画连载：邀请《深夜食堂》原作者安倍夜郎创作以中国大学生为主角的特别篇，每周在全家APP上更新一话，讲述学生们在全家便利商店找到温暖和力量的故事。 交互式动画：根据漫画内容制作短篇动画，让用户可以在特定情节做出选择，体验不同的故事发展。完成互动的用户可获得限定版「全家の深夜食堂」周边商品。 实体主题店：在大学周边的指定全家门市打造「深夜食堂」主题专区，不仅装潢风格模仿漫画场景，还提供漫画中出现的特色餐点，让学生们体验漫画中的温暖氛围。 通过这个IP合作，我们不仅能吸引《深夜食堂》的原有粉丝，还能让更多学生感受到全家便利商店如同漫画中的「深夜食堂」一般，是他们熬夜学习时温暖可靠的陪伴。'
            },
            example4: {
                title: '范例二',
                brief: 'X-TRAIL新产品推广活动 活动内容：X-TRAIL针对已婚且有小孩的40-54岁男性，喜爱户外活动如自行车、路跑和露营的消费群体，推出新车型，强调其宽敞舒适的车内空间，适合全家出游。在小红书和朋友圈上分享户外旅行规划和家庭回忆的故事，鼓励用户分享他们的家庭旅行照片。目标明确：精准锁定喜欢户外活动且经常载家人出游的中年男性，确保产品设计符合他们的生活方式和需求。平台互动：利用社交媒体的故事功能，增加用户参与度和品牌情感连结。实用性强：突出产品的空间舒适性和多功能性，提升购车意愿。竞争品牌：Toyota RAV4、Honda CR-V 的家庭户外活动推广活动。',
                spreadTheme: '“开启美好，X-Trail与你同行”\n' +
                    '传播主题创意说明\n' +
                    '本次传播主题“开启美好，X-Trail与你同行”源自于目标人群对于家庭时间的重视及对于质量生活的追求。结合Nissan X-Trail的产品特性，如宽敞的空间和安全技术，创造一个让消费者能感受到与家人共度时光的重要性和美好的独特观点。\n',
                coreIdea: 'Nissan X-Trail推出创新的在线到线下整合营销活动“X-Trail家庭探险计划”，旨在提升家庭之间的互动与产品体验。此活动包含在微信小程序上建立一个交互式的网站平台，家庭成员可以在此规划他们的旅行路线，并根据旅行地点获得定制的安全驾驶指南和旅游小贴士。'
            },
            example5: {
                title: '范例三',
                brief: '中国肯德基在 2024 推出四年前的人气限定款“大阪无骨脆鸡”产品系列，卖点是日式风味照烧酱汁搭配无骨脆鸡以及炒面，目标对象是喜好日本文化与日式口味的年轻消费者，以及喜爱尝鲜的美食爱好者，需要推荐中国明星艺人代言/ 并在中国的社交平台推广。竞争品牌：麦当劳、摩斯汉堡的日式限定美食推广活动。',
                spreadTheme: '「味蕾旅行，日式风情一脆难求」\n' +
                    '传播主题创意说明：\n' +
                    '这个主题利用了「味蕾旅行」这一社交热梗，符合年轻人对新奇与探索的心理需求。结合「大阪无骨脆鸡」的日式照烧风味，这个创意不仅刻画出品尝此菜品就如同进行一场味觉上的日本之旅，同时也挑战消费者去寻找与品尝这种独特的风味。\n',
                coreIdea: '肯德基中国联手中国最热门的音乐平台QQ音乐，推出「味蕾音乐节」。透过QQ音乐平台推出特别策划的「大阪无骨脆鸡音乐单曲」，每首歌曲都与一款特定口味的无骨脆鸡相关联，让消费者在品尝美食的同时，享受定制的音乐体验。此外，肯德基将在QQ音乐上举办在线音乐节，邀请中国本土歌手演出，并在活动期间内提供独家优惠券，让用户点购大阪无骨脆鸡。用户可以通过参与在线互动，如分享歌单、发表美食评论等，赢取独家限量周边商品。'
            },
            msgBox:{
                check:{
                    title:'用户任务助手',
                    ok:'返回修改',
                    cancel:'我知道了'
                }
            },
            loading:{
                check:'艾迪正在检查您的营销任务',
                expand:'艾迪正在帮你补全本次营销任务',
                title:'艾迪正在给您的营销任务起标题',
                brief:'艾迪正在编写Brief'
            }
        },
        Workspace: {
            functionMenu: {
                function1: '新品上市前策',
                function2: '社交口碑营销',
                function3: '整合营销创意',
                function4: '市场情报官',
                function5: '带货短视频创意',
                function6: '全球创意案例',
                function7: '头脑核爆'
            },
            historyMenu: {
                title: '历史记录',
            },
            copyright: '艾思智创出品',
            menu: {
                team: '团队',
                usage: '用量情况',
                settings: '设置',
                exit: '退出登录'
            },
            modal: {
                update: {
                    title: '修改brief名称',
                    placeholder: '请输入brief名称'
                },
                delete: {
                    message1: '此操作将永久删除brief，',
                    message2: '是否继续？'
                },
                settings: {
                    title: '设置',
                    tab1: {
                        title: '通用',
                        label1: {
                            word1: '用',
                            word2: '户',
                            word3: '名:',
                        },
                        label2: {
                            word1: '邮',
                            word2: '箱:',
                        },
                        label3: {
                            word1: '语',
                            word2: '言',
                            word3: '环',
                            word4: '境:',
                        },
                        select: {
                            option1: '简体中文',
                            option2: '繁體中文',
                        },
                        label4: {
                            word1: '团',
                            word2: '队',
                            word3: '成',
                            word4: '员:',
                        },
                        label5: '删除所有聊天',
                        button5: '删除全部',
                        label6: '修改密码',
                        button6: '点击修改'
                    },
                    tab2: {
                        title: '搜索偏好',
                        label1: {
                            word1: '地',
                            word2: '区',
                        },
                        label2: {
                            word1: '语',
                            word2: '言',
                        },
                        placeholder1: '请选择地区',
                        placeholder2: '请选择语言'
                    },
                    changePassword: {
                        step1: {
                            button1: "通过手机修改",
                            button2: "通过邮箱修改",
                            notice: "请联系管理员设置手机号或邮箱后再修改密码"
                        },
                        step2: {
                            notice1: "为了您的账号安全，需要验证您的手机",
                            notice2: "为了您的账号安全，需要验证您的邮箱",
                            label1: "验证码",
                            placeholder1: "请输入验证码",
                            button1: "获取验证码",
                            button2: "重新发送"
                        },
                        step3: {
                            label1: "新密码",
                            placeholder1: "请输入新密码",
                            label2: "确认密码",
                            placeholder2: "请再次输入新密码",
                        },
                        buttons: {
                            ok: "确认",
                            cancel: "取消"
                        }
                    }
                }
            }
        },
        MoreIdea: {
            button: {
                cancel: '取消',
                ok: '完成',
                help: '使用指南',
                finishEdit: '完成替换',
                cancelEdit: '取消替换',
                moreIdea: '给我更多',
                replace: '替换原文',
                export: '下载为',
                wordCn: 'Word（中文）',
                wordEn: 'Word（英文）',
                freeMind: 'FreeMind',
                markdown: 'Markdown'
            },
            message: {
                error1: "请先选择要脑暴的方案内容",
                error2: '至少选一个选项',
                error3: '至多选一个选项'
            },
            tour: {
                pre: '上一步',
                next: '下一步',
                finish: '我理解了'
            },
            tour1: {
                step1: '在这里选择想替换的内容',
                step2: '点击获取更多创意（至多5次）',
                step3: '在这里查看更多创意',
                step4: '点击翻页',
                step5: '选择创意并替换至原文对应的地方'
            },
            tour2: {
                step1: '艾迪已经把原本的主推荐创意帮您放入待脑暴区了，可以点击“开始脑暴”来生成更多的创意。当看到您满意的创意后，可以勾选出来，并点击“替换原文”，然后它就会替换左侧原方案中的对应部分'
            }
        },
        FullResearch: {
            placeholder1: '请告诉艾迪这次情报调研的内容。如，xxx产品将于下个月上市，请帮我做一下关于它和竞品，行业和市场的调研简报'
        },
        ShortVideoScript: {
            label1: '本次带货的产品（必填）',
            label2: '智能脚本类型选择（选填）',
            label3: '拍摄条件（选填）',
            placeholder1: '请尽可能详细描述你这次需要拍摄带货视频的产品，它可以是实物产品，也可以是电影，音乐会，演唱会，游戏等服务类产品',
            placeholder2: '口播/剧情/开箱/场景应用/促销/趣味互动 等',
            placeholder3: '请输入必须出现的元素，参考但不限于：\n' +
                '1. 产品（包装、颜色、大小...）\n' +
                '2.演员角色（女友、同事、妈妈...）\n' +
                '3.场地描述（家里、餐厅、海边...）\n' +
                '4.天气（艳阳天、雨天、暴雨...）\n' +
                '5.时间（夕阳、白天、半夜...）\n' +
                '6.发生的事件（晚宴、婚礼、同学会...）\n' +
                '7.背景场景（对话、吵架、脱口秀）\n' +
                '8.服装造型道具\n' +
                '9.特别需要规定拍摄的内容',
            placeholder4: '上传本次带货产品相关的介绍文档/资料 PDF',
            placeholder5: '上传相近行业或产品的爆款带货视频脚本',
            button: {
                uploadInfo: '产品资料<span class="upload-text">上传</span>（选填）',
                uploadReference: '爆款脚本<span class="upload-text">上传</span>（选填）',
                generateIdea: '开始'
            },
            example1: {
                marketingTask: '资生堂\n' +
                    '产品名称：资生堂精华水\n' +
                    '产品昵称：资生堂红水\n' +
                    '产品功效：亮白皮肤\n' +
                    '核心卖点：嫩肤不薄皮 亮白可持续\n' +
                    '卖点支撑：1.微精粹成分；2.果冻芯包装科技\n',
                scriptCondition: '内容要求\n' +
                    '1. 开头3s，以下方式选一\n' +
                    '引发好奇法 – “身边单身姐妹都用这个，独家大招放给你们”\n' +
                    '营销暗示法 – 这个产品，我们一直断货\n' +
                    '提问开头法 – 宝宝们你们知道，肌肤有多脆弱吗\n' +
                    '生活带入法 – 每天哪怕只有一分钟，也要做的护肤的功课是什么？\n' +
                    '感叹效果法 – 姐妹们，这个红水真的是，太顶了\n' +
                    '人群买点法 – 熬夜熬的都是我们姐妹的青春\n' +
                    '2. 中间，以下方式选一，但不管哪种内容，都要提到不薄皮这个卖点\n' +
                    '品牌力+产品使用感+评价 – 简单直接说卖点，强调使用感\n' +
                    '产品介绍+成分介绍+上脸使用 – 这个要求皮肤好，怼脸拍\n' +
                    '3. 结尾，非常非常自信的推荐粉丝去买，展现出“就该推，就该买”感觉\n' +
                    '\n' +
                    '拍摄要求：\n' +
                    '1. 需要产品各种角度特写时长超15s（LOGO无反光；无指纹手印污垢等出现，产品不要镜像！）\n' +
                    '2. 需要有产品露出/产品上脸的镜头/图片\n' +
                    '3. 画面色调无多度修饰，营造素人随心拍分享产品感受的氛围，但一定要整洁\n' +
                    '4. 背景整洁美观\n' +
                    '5. 视频一定要竖版拍摄\n'
            },
            example2: {
                marketingTask: '上新了故宫_春日游小托特包\n' +
                    '品牌背书：上新了故宫是故宫博物院唯一官方出品内容IP，至今收获了上亿粉丝，是故宫文化的传承者。有着顶级艺人和顶级流量的加持，创造多次热门话题和爆款产品，是东方美学代表，文化创新的代名词。\n' +
                    '产品名称：春日游小托特包\n' +
                    '核心卖点：其中包括设计特别点：腕带设计根据其受力点去做的产品设计久提不勒双手提，均衡平摊受力，更加结实耐用。表面外框处做以凸起感增强包型立体造型，从而增加包包整体精致感，\n' +
                    '卖点支撑：设计灵感来自于《朱瞻基行乐图》中的一处景象体现出当时娱乐的场面，以“云淡风轻近午天，傍花随柳过前川。时人不识余心乐，将谓偷闲学少年”宋代诗人程颢所描绘的春日中的春光及游玩其中的自在心情融合在设计当中，将春暖花开“包”入怀中。\n' +
                    '穿搭推荐：可以手拎也可以长肩带斜挎。做了立体工艺，能保持挺立的包型，也增大了收纳空间。开合方式作为拉链式，减少物品丢失提升安全感。颜色低调温柔可以搭配任何低饱和度服装。\n',
                scriptCondition: '内容要求\n' +
                    '开头：上新了故宫是故宫博物院唯一官方出品时尚箱包，明星同款创造多次热门话题和爆款产品，是东方美学代表，文化创新的代名词。\n' +
                    '中间：可以手拎也可以长肩带斜挎。做了立体工艺，能保持挺立的包型，也增大了收纳空间。开合方式作为拉链式，减少物品丢失提升安全感。颜色低调温柔可以搭配任何低饱和度服装。\n' +
                    '结尾：上新了故宫文创箱包给你大牌的品质，只要国货的价格，结合中西文化，创造优质的产品\n' +
                    '拍摄要求\n' +
                    '需要出现故宫场景，上新了故宫明星片段\n' +
                    '需要出现产品特写\n' +
                    '需要有模特背包上身的镜头\n'
            },
            example3: {
                marketingTask: '禾曰石榴汁\n' +
                    '产品名称：NFC低温鲜榨纯石榴汁\n' +
                    '产品卖点：\n' +
                    '1、不添加糖不添加水的纯鲜榨石榴汁\n' +
                    '2、鲜果榨取非浓缩还原\n' +
                    '3、HPP超高压灭菌工艺\n' +
                    '4、全程冷链运输\n' +
                    '5、营养原汁\n' +
                    '6、三颗石榴鲜榨成一瓶石榴汁\n' +
                    '7、果汁含量100%\n' +
                    '8、不添加一滴水\n' +
                    '9、到手8瓶，性价比高\n' +
                    '\n' +
                    '购买人群：白领、爱喝果汁人群、美白人群、养生人群、爱吃水果人群\n',
                scriptCondition: '内容要求\n' +
                    '开头：打季节限定/打鲜榨/打三颗石榴榨出一瓶/打配料表\n' +
                    '中间：卖点介绍\n' +
                    '结尾：打养生、打性价比等\n' +
                    '\n' +
                    '拍摄要求\n' +
                    '需要出现使用场景镜头\n' +
                    '需要出现特写镜头\n' +
                    '需要出现功能展示镜头\n' +
                    '需要出现人和产品互动镜头\n'
            },
            scriptTypes: {
                type_1: '口播类',
                type_1_1: '直面镜头解说型',
                type_1_2: '旁白配音型',
                type_1_3: '问答互动型',
                type_1_4: '专家讲解型',
                type_2: '剧情类',
                type_2_1: '生活场景还原',
                type_2_2: '前后对比展示',
                type_2_3: '买点解决情景剧',
                type_2_4: '产品使用小故事',
                type_2_5: '温情感人小剧场',
                type_3: '演示类',
                type_3_1: '产品功能展示',
                type_3_2: '使用教程步骤',
                type_3_3: '开箱测评',
                type_3_4: '对比实验',
                type_3_5: '真实使用效果',
                type_4: '趣味类',
                type_4_1: '创意特效',
                type_4_2: '转场拼接',
                type_4_3: '舞蹈搭配',
                type_4_4: '挑战玩法',
                type_4_5: '趣味实验',
                type_5: '访谈类',
                type_5_1: '用户真实评价',
                type_5_2: '街头采访',
                type_5_3: '专家访谈',
                type_5_4: '明星代言推荐',
                type_6: '记录类',
                type_6_1: '生产制作过程',
                type_6_2: '工作日常记录',
                type_6_3: '幕后花絮',
                type_6_4: '活动纪实',
                type_7: '混剪类',
                type_7_1: '产品亮点集锦',
                type_7_2: '用户反馈合集',
                type_7_3: '精彩瞬间剪辑',
                type_7_4: '活动回顾',
                type_8: '直播回放类',
                type_8_1: '直播精彩片段',
                type_8_2: '互动答疑集锦',
                type_8_3: '产品讲解回放',
                type_8_4: '活动直播剪辑'
            },
        },
        Tips:{
            title:'05 全球创意案例',
            mode:{
                title1:'任务查找',
                title2:'创意查找'
            },
            input:{
                label1_1:'请输入本次的营销任务来查找案例：',
                label1_2:'请输入您的营销创意来查找案例：',
                placeholder1_1:'Nike要在24年8月延续Pegasus41的推广，产品昵称飞马，主要针对跑步人群，是非常好的入门跑鞋，也可以是平时跑步训练的基础款，同时也可以作为休闲穿搭和城市轻徒步的必备单品。价格适中，针对人群18-35岁，对品牌和产品有忠诚度，年轻有活力的城市年轻人。',
                placeholder1_2:'麦门和洞门又联动了！玩出新鲜感。8月28号，麦当劳携手潮流品牌CROCS打造系列周边，以「Crocs 洞洞鞋」为灵感来源，推出等比例缩小的洞洞鞋挂坠，总共10款。款式丰富，创意多多，其中有汉堡，薯条等造型。同时，在周边设计上，麦当劳经典红黄配色和标志性笑脸清晰可见，俏皮可爱。此外还有一个细节，大多数洞洞鞋挂饰上有麦当劳开心乐园的标志。活动期间，消费者购买麦当劳开心乐园餐即有机会获得任意1款洞洞鞋挂饰。去年麦当劳和CROCS的联动合作，在社媒平台上引起强烈关注。麦麦ALL STAR全明星大赛的冠军员工们上脚联名款，登上时尚芭莎。今年麦当劳与CROCS的联名依旧是时尚潮流路线，通过打造时尚单品挂饰，让品牌印象深入人心。'
            },
            filter:{
                label1:'国外案例',
                tooltip1:'开启后仅查找海外案例',
                label3:'华人案例',
                tooltip3:'开启后搜索结果中将包含华人案例',
                label2:'其他行业',
                tooltip2:'开启后本行业案例将不会出现在结果中',
            },
            example1_1:{
                marketingTask:'霸王茶姬江苏即将与苏州博物馆联合合作，做一波在地化营销传播动作 需要深挖苏州博物馆建筑美学特色，挖掘出联名合作的策略方向，与霸王茶姬的以茶会友结合在一起，向消费者传递霸王茶姬在地化的品牌质感'
            },
            example2_1:{
                marketingTask:'星巴克 x team Wang 联名会员周边系列推广活动 星巴克携手team Wang，旨在通过跨界合作，为星巴克会员带来一系列独特的联名周边产品。此次活动将结合双方品牌特色，以创新的设计和高品质的产品，满足年轻消费者对个性化和时尚生活的追求。 加强星巴克品牌与年轻消费者的情感连接。 增强会员的品牌忠诚度，提升会员活跃度。 推广星巴克 x team Wang 联名会员周边系列，提高产品销量。 目标受众是星巴克忠实会员及潜在会员。 对team Wang设计风格有认同感的年轻群体。 主要利用中国的社交平台和线上商城进行推广，提升星巴克会员活跃度和新增会员数量。竞争品牌：瑞幸咖啡/ Manner 咖啡。'
            },
            example1_2:{
                marketingTask:'视频8月23日，KFC和乙女游戏恋与深空的联名上线。本次联名包含两款套餐，KFC与恋与深空共同推出「恋与深空心动套餐」和「恋与深空幸福套餐」，在线下主题门店限定出售，并打出“吃美味K门，享幸福人生”的宣传口号。购买套餐即可获得对应附赠，包含徽章套组/卡牌套组，以及游戏兑换礼包。根据官方预告，8月29日-9月2日期间，联名快闪活动还将登陆上海北外滩。23日联名套餐正式上线后，在猎人小姐（即恋与深空游戏玩家）的热情下，#肯德基#崩了喜提微博热搜。后续在社交媒体上流传的品牌内部流出的相关规定，显示出了KFC对此次联名的用心程度。网友热评：“让人抢不到是截止目前为止KFC最大的问题”。'
            },
            example2_2:{
                marketingTask:'8月26日，乐乐茶正式上线“消热搭档”新品，并推出相应周边。本次项目由“热”这个概念延伸，不仅是40度高温天气外在体感的热，还有因为工作等原因导致的内在的“上火”，新品“灭火菊花六宝茶”、“大橘青提”给大家降降火。活动套餐名也很形象地突出“降火”，一个是“不要玩火”套餐，另一个是“及时注水”套餐。远离火源、及时降温，生活不再“易燃易炸”。周边产品有：贴纸、消防杯，编织袋。'
            },
            button:{
                start:'开始',
                clear:'清空'
            },
            message:{
                error1:'请填写营销任务'
            }
        },
        Explosion:{
            title:'06 头脑核爆',
            mode:{
                title1:'IDEA 探索',
                title2:'IDEA 扩展'
            },
            input:{
                label1_1:'请输入本次的营销任务：',
                label1_2:'请输入已完成的IDEA：',
                placeholder1_1:'Under armour即将在2024年上新一款鞋slipspeed，它的核心卖点是鞋后跟可以折叠，折叠起来日常休闲可当拖鞋使用，鞋跟立起可以作为专业的训练鞋在运动中使用，品牌想借此新品上市，在推广产品的同时，突破品牌在消费者心中硬核专业的形象，拓展更多圈层人群，此鞋款受众18-30岁，主要竞品产品：Nike Metcon 9、Reebok Nano X4。',
                placeholder1_2:'Apple携手创意代理商Hungry Man/Los Angeles，借2024巴黎残奥会之际，推出励志短片《The Relay》。该广告突破性地展现了残疾与非残疾运动员并肩竞技的场景，借助Apple设备，强调运动无界限，科技平等赋能每一个人。短片通过四场激烈而温馨的比赛，包括游泳、跑步及自行车，证明了不论是克服障碍的坚韧，还是对胜利的渴望，所有运动员的心跳与汗水都同样炙热。《The Relay》不仅是iPhone与Apple Watch功能的展示，更是对人性共情和社会包容性的颂歌，有力传达了Apple产品设计理念——为每一个独一无二的你精心打造。'
            },
            filter:{
                label1_1:'接地气',
                label1_2:'更洋气',
                label2:'更放飞'
            },
            example1_1:{
                marketingTask:'雀巢阿拉比卡咖啡品种「Star 4」，强调低碳排放和供应链优化，特别吸引环保意识强烈和注重质量的咖啡爱好者，精准锁定中国市场中注重生活质量和环境永续的都市人士。这款咖啡不仅具有独特风味，还特别强调其对应气候变迁的创新性，适合日常饮用和特殊场合品尝。对标其他精品咖啡品牌，强调雀巢在环保创新、质量控制以及全球供应链优化上的优势。'
            },
            example2_1:{
                marketingTask:'全家便利商店的「开学季优惠」活动 活动内容： 全家便利商店在小红书上针对大学生举办了「开学季优惠」活动，发布各种折扣和优惠信息，同时在小红书上进行问答互动，解答学生的各种问题。 目标明确：精准锁定大学生群体，活动设计符合他们的需求和喜好。 平台互动：利用小红书的社交互动功能，增加用户参与度和品牌好感度。 实用性强：提供实际的折扣和优惠，直接提升门店销量。竞争品牌：7-ELEVEN、莱尔富、全家 FamilyMart 的开学季活动。'
            },
            example1_2:{
                marketingTask:'瑞幸咖啡与国产游戏巨擘《黑神话：悟空》跨界携手，于8月19日震撼发布“腾云美式”饮品。这款创新之作融入柠檬香椰云与IIAC金奖咖啡豆，带来一场味蕾上的清新奇遇。同步推出的短片，邀请每一位“天命人”共赴云端之旅，挑战命运。不仅如此，瑞幸更以大圣新形象装点全国门店，隐藏彩蛋——定制“瑞”字葫芦包装，寓意能量加持。此外，购买指定套餐即赠绝美黑神话3D限定A4光栅卡，限量版周边快速售罄，展现非凡热度。联名主题店空降杭州，复刻游戏实景，沉浸式体验等你探索，一品咖啡与神话交融的奇幻魅力。'
            },
            example2_2:{
                marketingTask:'蜜雪冰城携手创新营销，推出真人版短剧《雪王的穿越日记》，引领品牌故事新风潮。雪王变身多样角色，穿越冒险，每集2分钟紧凑剧情，幽默诙谐，令人捧腹。已播4集精彩各异：从雪舞王者到雪管家、雪特助、雪团宠，展现雪王多面魅力。幕后花絮更显诚意满满，导演与雪王默契互动，温馨笑料不断，深化粉丝情感联结，打造品牌内容营销新标杆。'
            }
        }
    },
    components: {
        PageHeader: {
            userCenter:'会员中心'
        },
        WaterfallPage: {
            button: {
                example: '参考范例',
                editBrief: '修改Brief',
                generatePlan: '生成创意',
                finishEdit: '完成修改',
                editPlan: '更多创意',
                fullPlan: '完成方案',
                export: '下载为',
                wordCn: 'Word（中文）',
                wordEn: 'Word（英文）',
                freeMind: 'FreeMind',
                markdown: 'Markdown'
            },
            mode: {
                crazy: '--疯狂创意模式'
            },
            input: {
                title: '本次ADGo的任务：'
            },
            brief: {
                title: 'Brief文件',
                viewSource: '查看数据来源',
                anchor: {
                    title: 'Brief'
                }
            },
            plan: {
                anchor: {
                    title1: '头脑风暴',
                    title2: '完整方案'
                }
            },
            dialog: {
                content1: '请确认你的brief',
                content2: '需要开始生成方案吗？',
                ok: '好',
                cancel: '再想想'
            },
            dialog3: {
                content1: '请确认你的brief',
                content2: '需要开始生成方案吗？',
            },
            complete: {
                message1: '艾迪已努力完成工作了！请您仔细阅读。'
            },
            waiting: {
                message1: '艾迪正在思考中，您请先喝杯咖啡，稍等我{waitingTime}分钟',
                message2: '（前方正在排队，请稍后）'
            },
            waiting2: {
                message1: '艾迪正在思考中，您请先喝杯咖啡，稍等我{waitingTime}分钟',
                message2: '（前方正在排队，请稍后）'
            },
            error1: {
                message1: '很抱歉，艾迪不知道把方案放哪去了',
                message2: '（系统报错，将为你重新生成）',
                redoTask: '重新生成'
            },
            error2: {
                message1: '很抱歉，艾迪生病回家了，请紧急联系他的老板“老张”。',
                message2: '（系统报错，请发送信息：'
            },
            error3: {
                title:'功能限制提示',
                message1: '很抱歉，艾迪已经全面升级了，您需要重新发起一次相同的任务来使用该功能。',
            },
            dialog2: {
                content1: '你确认要用当前主推荐创意来生成完整方案么？',
                content2: '点击“开始脑暴”按钮获得更多创意推荐',
                button1: '开始脑暴',
                button2: '传播计划',
                button3: 'KOL计划'
            },
            autoSave: '自动保存成功'
        },
        UsagePage: {
            ToolBar: {
                title: "用量情况",
                self: "本人",
                others: "其他成员"
            },
            chart: {
                title1: '月消耗',
                title2: '总消耗'
            },
            circle: {
                title: '消耗/剩余'
            },
            BalanceTable: {
                title1: '获取时间',
                title2: '状态',
                title3: '余额',
                title4: '过期时间',
                status1: '过期',
                status2: '可用'
            },
            TaskTable: {
                title: '实际使用情况',
                notice: {
                    content1: '代币消耗说明',
                    content2: '方案',
                    content3: '翻译',
                },
                title1: '功能',
                title2: '类型',
                title3: '次数'
            }
        },
        ShortVideoScript: {
            brief: {
                scriptType: '脚本类型：',
                scriptCondition: '拍摄条件：'
            },
            title1: '本品卖点梳理（请选择三个你希望进行拍摄的卖点）：',
            title2: 'Top10用户买点（请选择三个你希望进行拍摄的买点）：',
            title3: '根据卖点【{sellPoint}】和买点【{painPoint}】生成的创意：',
            title4: '短视频脚本：',
            title5: '审核意见：',
            button: {
                getPainPoints: '挖买点',
                getCreativitys: '出创意',
                moreCreativitys: '再来一个创意',
                getScripts: '出脚本',
                ok: '好',
                cancel: '再选选',
                downloadScript: '下载脚本',
                selectSellPoint: '重新出创意'
            },
            anchor: {
                text1: '卖点梳理',
                text2: '用户买点',
                text3: '脚本创意',
                text4: '拍摄脚本',
            },
            msgBox1: {
                title: '提示',
                content: '你确定用勾选的产品卖点让ADGO继续深挖用户买点么？'
            },
            msgBox2: {
                title: '提示',
                content: '你确定根据勾选的用户买点创作本次带货短视频的创意么？'
            },
            msgBox3: {
                title: '提示',
                content: '你确定需要再创作1个脚本创意么？'
            },
            msgBox4: {
                title: '提示',
                content: '你确定根据勾选的脚本创意来创作本次带货短视频的最终脚本么？'
            },
            errMsg1:'请勾选产品卖点',
            errMsg2:'请勾选用户买点',
            errMsg3:'请勾选脚本创意',
        },
        Tips:{
            button:{
                exportWord:'下载文件',
                moreCases:'使用现有维度',
                newDimension:'更新维度',
                change:'更多案例',
                getTips:'生成案例二创'
            },
            dimensionIndex:'第{i}批参考案例',
            dimension:'ADGo建议案例维度：',
            source :'来源：',
            reference: '参考案例：',
            tips:'案例二创',
            modal:{
                change:{
                    label:{
                        core:'核心关键词',
                        crowd:'人群维度',
                        industry:'行业维度',
                        platform:'平台维度',
                        strategy:'创意策略维度',
                        format:'内容形式维度'
                    },
                    placeHolder:{
                        core:'核心：万圣节、刘亦菲、哈利波特 等',
                        crowd:'人群：都市白领女性、小镇毕业大学生、35-45岁企业高管 等',
                        industry:'行业：快消、运动品牌、旅游、汽车 等',
                        platform:'平台：微信、抖音、微博、知乎 等',
                        strategy:'创意策略：情感营销、活动促销、教育内容、故事叙述 等',
                        format:'内容形式：短视频、图文、公众号内容、文章 等'
                    },
                    rules:{
                        crowd:'人群维度不能为空',
                        industry:'行业维度不能为空',
                        platform:'平台维度不能为空',
                        strategy:'创意策略维度不能为空',
                        format:'内容形式维度不能为空'
                    }
                }
            },
            warnings:{
                getTips:'请选择参考案例',
                moreCases:'请选择维度',
                maxCount:'最多只能生成3批参考案例'
            }

        },
        Explosion: {
            button:{
                exportWord:'下载文件',
                moreCases:'现有关键词',
                newDimension:'更新关键词',
                change:'更多IDEA',
                getTips:'IDEA二创'
            },
            dimensionIndex:'第{i}批IDEA',
            dimension:'ADGo建议关键词：',
            tips:'IDEA二创',
            modal:{
                change:{
                    label:{
                        core:'核心关键词',
                        crowd:'人群关键词',
                        industry:'行业关键词',
                        platform:'平台关键词',
                        strategy:'创意策略关键词',
                        format:'内容形式关键词'
                    },
                    placeHolder:{
                        core:'核心：万圣节、刘亦菲、哈利波特 等',
                        crowd:'人群：都市白领女性、小镇毕业大学生、35-45岁企业高管 等',
                        industry:'行业：快消、运动品牌、旅游、汽车 等',
                        platform:'平台：微信、抖音、微博、知乎 等',
                        strategy:'创意策略：情感营销、活动促销、教育内容、故事叙述 等',
                        format:'内容形式：短视频、图文、公众号内容、文章 等'
                    },
                    rules:{
                        crowd:'人群关键词不能为空',
                        industry:'行业关键词不能为空',
                        platform:'平台关键词不能为空',
                        strategy:'创意策略关键词不能为空',
                        format:'内容形式关键词不能为空'
                    }
                }
            },
            warnings:{
                getTips:'请选择要仿写的IDEA',
                moreCases:'请选择关键词',
                maxCount:'最多只能生成4批IDEA',
                keywords:'请填写关键词'
            },
            confirm:{
                title:'确认生成',
                content:'需要再生成一批IDEA拓展么？'
            }
        }
    }
}